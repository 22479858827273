<template>
  <section class="shop-item-inquire">
    <div
      class="shop-item-inquire__background"
      :style="{
        backgroundImage: `url(${artwork.header_image})`,
      }"
    ></div>
    <div class="shop-item-inquire__content">
      <h2 class="shop-item-inquire__title text-title">Inquire</h2>
      <ValidationObserver
        class="shop-item-inquire__form"
        v-slot="{ invalid }"
        tag="div"
        ref="observer"
      >
        <ValidationProvider
          tag="div"
          class="shop-item-inquire__input"
          :rules="{ required: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <SubscriptionInput
            class="form-control--inquire"
            placeholder="Art Name"
            v-model="form.artName"
            :disabled="true"
            :validateError="errors[0]"
          ></SubscriptionInput>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="shop-item-inquire__input"
          :rules="{ required: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <SubscriptionInput
            class="form-control--inquire"
            placeholder="Your Name"
            v-model="form.name"
            :validateError="errors[0]"
          ></SubscriptionInput>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="shop-item-inquire__input"
          :rules="{ required: true, email: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <SubscriptionInput
            class="form-control--inquire"
            placeholder="Email Address"
            v-model="form.email"
            :validateError="errors[0]"
          ></SubscriptionInput>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="shop-item-inquire__input"
          :rules="{ required: true, phone_number: true }"
          v-slot="{ errors }"
        >
          <SubscriptionInput
            class="form-control--inquire"
            placeholder="Phone Number"
            v-model="form.phone"
            :validateError="errors[0]"
          ></SubscriptionInput>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          class="shop-item-inquire__input"
          :rules="{ required: true, min: 2, max: 255 }"
          v-slot="{ errors }"
        >
          <BGATextarea
            class="form-control--inquire"
            placeholder="Message"
            v-model="form.message"
            :maxLength="255"
            :validateError="errors[0]"
          ></BGATextarea>
        </ValidationProvider>
        <BaseButton
          v-if="captchaVerifided"
          class="shop-item-inquire__button button--white"
          text="SUBMIT"
          :disabled="invalid || submiting"
          @click="submit"
        ></BaseButton>
        <VueRecaptcha
          v-else
          ref="recaptcha"
          :sitekey="sitekey"
          @verify="captchaVerifided = true"
          @error="captchaVerifided = false"
        />
      </ValidationObserver>
    </div>
    <IconButton
      class="shop-item-inquire__close button--md button--outline-white"
      @click="$router.push(`/artworks/${artworkSlug}`)"
    >
      <IconClose width="18" height="18"></IconClose>
    </IconButton>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import SubscriptionInput from "@/components/inputs/SubscriptionInput.vue";
import DragAndDropFiles from "@/components/DragAndDropFiles.vue";
import BGATextarea from "@/components/inputs/BGATextarea.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { VueRecaptcha } from "vue-recaptcha";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    IconButton,
    BaseCheckbox,
    SubscriptionInput,
    DragAndDropFiles,
    BGATextarea,
    IconClose,
    VueRecaptcha,
  },
  data() {
    return {
      captchaVerifided: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      artworkSlug: this.$route.params.slug,
      submiting: false,
      form: {
        artName: "",
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters("publicapi/shop", {
      artwork: "getArtPiece",
    }),
  },
  async created() {
    await this.apiGetArtPiece(this.$route.params.slug);
    this.form.artName = this.artwork.title;
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Artworks",
        to: "/artworks",
        clickable: true,
      },
      {
        title: this.form.artName,
        to: `/artworks/${this.$route.params.slug}`,
        clickable: true,
      },
      {
        title: "Inquire",
        to: `/artworks/${this.$route.params.slug}/inquire`,
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/shop", ["apiFormsInquire", "apiGetArtPiece"]),
    async submit() {
      this.submiting = true;
      let success = await this.apiFormsInquire({
        art_piece_name: this.form.artName,
        username: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        message: this.form.message,
      });
      if (success) {
        this.form.email = "";
        this.form.name = "";
        this.form.phone = "";
        this.form.message = "";
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      }
      this.submiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-item-inquire {
  position: relative;
  padding: 32rem 31.5rem 23rem;
  @media screen and (max-width: $xs) {
    padding: 27rem 25px 23rem;
  }
  &__background {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 58.4rem;
    z-index: 0;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $xs) {
      height: 33.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.45);
      z-index: 1;
    }
    &::after {
      content: "";
      bottom: -1px;
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        0deg,
        rgba($color: $lightBlack, $alpha: 1) 0%,
        rgba($color: $lightBlack, $alpha: 0) 100%
      );
      z-index: 1;
    }
    &-image {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
  }
  &__close {
    position: absolute;
    top: 4.5rem;
    right: 10.5rem;
    z-index: 1000;
    @media screen and (max-width: $xs) {
      right: 25px;
    }
  }
  &__title {
    font-size: 4rem;
    margin-bottom: 4.5rem;
    text-align: center;
  }
  &__description {
    font-weight: 100;
    margin-bottom: 2.5rem;
    text-align: center;
  }
  &__form {
    max-width: 39rem;
    margin: 0 auto;
  }
  &__input {
    margin-bottom: 4.5rem;
  }
  &__button {
    min-width: 100%;
  }
  /* &__contacts {
    margin-top: 12rem;
  }
  &__sign-up {
    margin: 15rem auto 0;
    max-width: 60rem;
  } */
}
</style>
